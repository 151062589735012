<template>
  <div class="index">
    <div class="commonHeader">
      <div class="headercontain">
        <div class="headerLeft" @click="routeVue(0)">
          <img src="../assets/logo.png" alt />
        </div>
        <div class="headerRight">
          <div class="navbar">
            <div
              class="navbar_item"
              v-for="(item,index) in navbarList"
              :class="navbarIndex==index?'navbar_itemActive':''"
              @click="routeVue(index)"
            >{{item}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view />
    </div>
    <!--  -->
    <div class="footer">
      <div class="footerMain">
        <div class="footerNav">
          <!-- <span>加入我们</span> | -->
          <span @click="showImg(4)">用户服务协议</span> |
          <span @click="showImg(5)">隐私政策</span> |
          <span @click="showImg(3)">营业执照</span> |
          <span @click="showImg(1)">广播电视节目制作经营许可证</span> |
          <span @click="showImg(2)">网络文化经营许可证</span> |
          <span @click="showImg(6)">出版物经营许可证</span> |
          <span @click="hrefWaring">网上有害信息举报</span>
        </div>
        <div class="footercompany" @click="hrefGov">
          粤ICP备19152244号-1
          <i>·</i> 广州新芽教育科技有限公司版权所有
        </div>
        <div></div>
      </div>
    </div>
    <!--  -->
    <div class="ad">
      <div class="adList">
        <div class="adItem">
          <div class="adItemImg"></div>
          <p>扫码下载</p>
          <div class="adItemDetail">
            <img src="../assets/downCode.png" alt />
            <p class="green">手机扫描二维码下载</p>
            <p>小新课堂APP</p>
          </div>
        </div>
        <div class="adItem">
          <div class="adItemImg"></div>
          <p>商务合作</p>
          <div class="adItemDetail" style="padding:20px 18px 0;">
            <img src="../assets/icon_wx.jpg" alt />
            <p class="green" style="font-size:14px !important;text-align:left;">商务合作咨询请添加微信：goring8566</p>
          </div>
        </div>
        <div class="girl" :style="{display:!start?'block':'none'}">
          <img src="../assets/girl.png" alt />
        </div>
      </div>
      <div class="scrollTop" v-if="topShow" @click.stop="backTop">
        <img src="../assets/backTop.png" alt />
      </div>
    </div>
    <div class="imageMask" v-if="imgShow">
      <div class="imageContain">
        <h3>{{imgText}}</h3>
        <div class="imageOpen" @click="openImg">
          <img src="../assets/openVideo.png" alt />
        </div>
        <div class="content">
          <img v-if="imgText=='营业执照'" src="../assets/zhizhao.jpg" alt />
          <img v-if="imgText=='广播电视节目制作经营许可证'" src="../assets/zhizuo.jpg" alt />
          <img v-if="imgText=='网络文化经营许可证'" src="../assets/jingying.jpg" alt />
          <img v-if="imgText=='出版物经营许可证'" src="../assets/chuban.jpg" alt />
          <div class="text" v-if="imgText=='用户服务协议'">
            一、服务条款的确认
            <br />本协议的缔约双方为广州新芽教育科技有限公司（以下简称“新芽教育”）与使用新芽教育“小新课堂服务”的用户。本协议通过互联网线上签订，用户在使用小新课堂APP提供的各项服务之前应仔细阅读本用户协议，用户通过注册程序完成注册账号操作即视为用户已了解并完全同意本服务条款各项内容。
            <br />二、服务说明
            <br />1、小新课堂服务是指以新芽教育提供的名为“小新课堂”相关的移动端应用程序为核心的网络商品交易平台，如：小新课堂APP端等随小新课堂业务扩展和衍生的相关技术产品及服务。
            <br />2、本协议项下所称的商品，指用户可合法交易的“数字化教育资源内容”。
            <br />3、小新课堂服务可能包含交易平台或其它服务，具体服务内容根据小新课堂平台的实际运营方式提供。
            <br />4、交易平台：小新课堂向买家提供商品展示和在线交易，并向卖家提供商品管理、订单管理、宣传和销售等行为。作为买家，用户可自由平等地对他人商品进行收藏、分享、购买等操作。买卖合同关系建立于买家用户与卖家用户之间，小新课堂平台并非买卖合同关系的当事方，发票凭证由卖家向买家提供。
            <br />5、如发生以下任何一种情形，小新课堂平台有权随时中断或终止向用户提供本协议项下的任何服务，而无需提前对该用户进行通知，也无需承担因中断或终止服务对此用户造成的任何影响责任：
            <br />5.1、根据可适用的法律法规、国家政策、行政司法机关的要求或合同约定，小新课堂平台认为有义务或有必要中断或终止向用户提供服务的；
            <br />5.2、小新课堂平台认为用户填写的用户资料、头像、昵称、商品信息、订单评价存在违反法律法规或国家政策要求，或明显缺乏真实性或具有欺诈性的；
            <br />5.3、小新课堂平台认为用户的行为存在恶意或有不当行为嫌疑，会对小新课堂平台的正常运营和用户正常使用带来潜在危险性的，如信用卡套现、洗钱、诈骗、刷单、恶意评价、诋毁或攻击其他用户、不文明用语经警告后仍无纠正行为的、擅自留下个人联系方式（包括但不限于微信号、手机号、QQ号、邮箱等）、非正常登录的；
            <br />6、为了保证向用户提供高质量的服务，小新课堂需要不定期地对提供交易服务的平台以及相关设备进行维护或者升级，如因此类情况而造成网络服务在合理时间内的中断，小新课堂为此不必承担任何责任，但小新课堂应尽可能事先通知用户，请用户理解。
            <br />7、鉴于网络服务的特殊性，小新课堂有权随时变更、中断或终止部分或全部的网络服务。变更、中断或终止某些服务时，小新课堂保留不经事先通知的权利，亦无需对用户和第三方承担任何责任。
            <br />8、由于小新课堂服务变更、中断或终止，或者用户自行停止使用小新课堂服务，造成后续相关交易无法继续进行的，小新课堂平台有权按照尽可能保护无过错方利益的原则，进行变更、撤销或终止交易，交易双方应当给予配合，并承担各自操作所应承担的成本和责任。
            <br />三、交易规则
            <br />9、用户作为买家时，承诺遵守以下小新课堂平台的交易规则：
            <br />9.1、充分了解商品信息
            <br />买家应仔细了解订单页面中所包含的全部内容，包括但不限于商品价格、商品图片、小新课堂承保范围和赔偿条款等。
            <br />9.2、支付交易货款
            <br />在订单提交并成立之后，用户应在付款页面的提示时间内通过网上支付平台完成货款的支付。小新课堂平台接受卖家委托向买家收取商品货款，当买家支付完全部货款后，即视买家已履行了支付义务。
            <br />9.3、交易成功
            <br />买家在订单购买成功后，且在指定退款时间内未提交退款请求也未向小新课堂客服表达要退款意愿的，小新课堂即视本次订单委托已成功完成交易，并将买家所支付的货款在扣除小新课堂平台技术服务费后支付给卖家。
            <br />9.4、退货退款规则及争议解决
            <br />买家与卖家因订单内容中途履行发生变化时，双方应及时跟小新课堂平台客服沟通反馈，由小新课堂客服协商后进行处理。
            <br />在处理退换货的过程中发生争议的，买家应与卖家根据订单内容的约定确定双方的权利义务，承担各自的责任。小新课堂平台不是买卖关系的当事方，不对买卖关系下的争议承担任何责任，但小新课堂平台会协助买卖双方进行协商，推动问题解决，并有权制定交易纠纷协调处理规则，买卖双方应服从该规则并配合争议解决，否则小新课堂平台有权依据本协议对纠纷的过错方进行处罚或追究违约责任。
            <br />四、用户使用细则
            <br />10、通过手机注册或使用第三方平台账号（如微信）登录小新课堂平台的用户被视为同意本协议的条款，并允许小新课堂进行以下操作:
            <br />10.1、获得用户在第三方平台上的授权可使用的指定信息；
            <br />10.2、分享小新课堂的内容到用户的第三方平台；
            <br />10.3、允许小新课堂向注册和授权的手机号码发送交易短信、验证码、活动通知等由小新课堂官方平台发出的短信。在使用小新课堂平台服务过程中，用户若不希望接收小新课堂的短信，可向运营商发送指令不再接收。
            <br />10.4、在成功登录之后，用户可以在“我”=>“个人信息”下修改昵称和头像，或在“我”=>“设置”下修改密码。
            <br />11、用户在使用小新课堂平台服务过程中，必须遵循以下原则：
            <br />11.1、 遵守中国有关的法律和法规，不得利用小新课堂平台制作、上传、复制、发布、传播或者转载如下内容：
            <br />a、违反宪法所规定的基本原则的；
            <br />b、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            <br />c、损害国家荣誉和利益的；
            <br />d、煽动民族仇恨、民族歧视，破坏民族团结的；
            <br />e、破坏国家宗教政策，宣扬邪教和封建迷信的；
            <br />f、散布虚假的、骚扰性信息，扰乱社会秩序，破坏社会稳定的；
            <br />g、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            <br />h、恐吓、侮辱或者诽谤他人，侵害他人合法权益的；
            <br />i、含有法律、行政法规禁止的其他内容的信息。
            <br />11.2、遵守所有与小新课堂平台服务有关的网络协议、规定和程序；
            <br />11.3、不得为任何非法目的而使用小新课堂平台；
            <br />11.4、不得以任何形式在小新课堂平台发布任何商业性广告或其他任何类型的商业信息，包括但不限于小新课堂竞品信息，个人的微信号、手机号、QQ号、邮箱等；
            <br />11.5、不得以任何方式利用小新课堂直接或间接从事违反中国法律法规以及社会公德的行为；
            <br />11.6、发布的信息不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益。
            <br />12、用户有权对商品信息、服务质量、客服服务等服务持不同意见，但不能因个人偏见主观地对他人或小新课堂平台进行人身攻击、恐吓、威胁、敲诈、诽谤、恶意评价等违法或干扰平台正常运营秩序。
            <br />13、如用户在使用网络服务时违反本协议条款，或其它可适用的法律法规、国家政策、平台规则和公告，小新课堂平台有权要求用户进行改正，或直接采取一切必要的措施，以减轻或消除用户的不当行为，具体行为包括并不限于：
            <br />13.1、向国家执法机关提供用户的违法事实及证据；
            <br />13.2、向用户提出警告；
            <br />13.3、修改或屏蔽违规内容，包括但不限于将违规信息和违规商品删除；
            <br />13.4、限制用户评论、转售、购买、提现等操作；
            <br />13.5、部分或全部扣除商品货款，或代购服务费
            <br />13.6、部分或全部扣除保证金；
            <br />13.7、冻结或注销用户账户，终止向用户提供服务，或拒绝用户使用之前违规账户所使用的设备使用小新课堂平台；
            <br />五、知识产权
            <br />14、小新课堂所有设计UI、功能名称、操作流程、交互设计的技术与所有程序，均为新芽教育所享有的知识产权和标识，任何人不得使用、复制或用作其他用途。小新课堂专有内容、原创内容和其他通过授权取得的独占或者独家内容享有完全知识产权，未经小新课堂许可，任何单位和个人不得私自复制、传播、展示、镜像、上载、下载、使用，或者从事任何其他侵犯小新课堂知识产权的行为，否则将追究相关法律责任。
            <br />15、用户通过小新课堂服务上传到小新课堂上的任何内容，用户皆授予小新课堂在世界范围内可以基于商业目的，永久的、免费的复制、翻译、发行、重新编排、节选等使用或采用任何形式再传播。
            <br />16、在未得到著作权人的授权时，请不要将他人的作品全部或部分复制发表到小新课堂。如用户上传的内容侵犯了第三方的知识产权或其他权利，小新课堂不承担任何法律责任，所有后果由用户自行承担。如果第三方提出关于知识产权的异议，小新课堂有权根据实际情况删除相关的内容，有权追究用户的法律责任，如因此给小新课堂或任何第三方造成损失的，用户应负责全额赔偿。
            <br />六、免责申明
            <br />17、除法律规定的涉及侵权、诽谤等事由外，用户发表的言论如有涉及侵权、诽谤等法律明确规定的事项情况，仅代表其个人观点，小新课堂将第一时间协助删除，但并不因此代表小新课堂承担该用户因侵权、诽谤等造成的法律责任。
            <br />18、就下列相关事宜的发生，小新课堂亦不承担任何法律责任：
            <br />18.1、用户将个人密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其它非小新课堂原因导致的个人信息的泄漏，及因此而产生的个人钱包余额被盗；
            <br />18.2、用户在申请使用小新课堂网络服务时因个人资料有任何变动，但未及时更新，由此类因个人信息不真实而引起的问题及后果；
            <br />18.3、小新课堂根据法律规定或政府相关政策要求提供用户个人真实信息；
            <br />18.4、任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性平台关闭；
            <br />18.5、因不可抗力导致的任何后果；
            <br />18.6、小新课堂在各服务条款及声明中列明的使用方式或免责情形。
            <br />七、协议的构成与修改
            <br />19、本协议内容包含的条款及小新课堂平台就小新课堂服务不时发布或组织订立的各类协议、平台规则和公告，所有前述内容均为本协议不可分割的组成部分，与本协议正文具有同等法律效力。
            <br />20、小新课堂平台有权根据需要随时修改本协议的有关条款、平台规则和公告，变更后的内容一旦发布即生效，自动成为本协议的一部分。如果用户不同意相关变更，应当立即停止使用小新课堂服务。如果用户继续使用本服务，则视为用户接受变更后的条款与条件。小新课堂建议用户在每次使用小新课堂平台服务之前检查本协议以及留意平台发布的规则、公告通知。
            <br />八、适用法律与管辖
            <br />21、本协议条款之解释与适用，以及本协议有关的争议，均应适用中华人民共和国法律，并排除其法律冲突规则使用。
            <br />22、若双方在本协议项下发生争议，任何一方有权将争议提交新芽教育所在地法院通过诉讼解决。
          </div>
          <div class="text" v-if="imgText=='隐私政策'">
            小新课堂隐私保护概述
            <br />『小新课堂』非常重视对您的个人隐私保护，有时候我们需要某些信息才能为您提供您请求的服务，本隐私声明解释了这些情况下的数据收集和使用情况。请阅读以下隐私政策，以了解本公司将收集哪些信息，本公司将该等信息用于哪些目的，以及您就您的个人资料享有的权利。您向本公司提供您的个人资料，即表示您同意本隐私政策，以及同意本公司为本隐私政策所述的目的而收集、使用、披露、获取、共享、转移、储存及处理您的个人资料（除非相关法律要求需获得您的明示同意）。本公司不会超出收集个人资料时所告知的目的而使用您的个人资料，如确需超范围使用，则本公司会及时告知您，并在重新获得您的同意后予以使用。
            <br />1、【小新课堂】将对用户所提供的资料进行严格的管理及保护，使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄露。在未得到您的许可之前，我们不会把您的任何个人信息提供给无关的第三方（包括公司或个人），但下列情况除外：
            <br />a、事先获得用户的明确授权；
            <br />b、根据有关的法律法规要求；
            <br />c、按照相关司法机构或政府主管部门的要求；
            <br />d、只有提供用户的个人资料，才能提供用户所需要的产品和服务。
            <br />2、为便于用户查询自己的交易状态或历史记录，【小新课堂】会保存用户使用【小新课堂】服务产生的交易信息。
            <br />3、 为更好地识别用户的身份以充分保护用户的账户安全，当用户访问【小新课堂】时，【小新课堂】可能会记录用户操作的相关信息，包括但不限于用户的计算机IP地址、设备标识符、硬件型号、操作系统版本以及与【小新课堂】平台相关的日志信息。
            <br />4、外部链接
            <br />本站含有到其他网站的链接。【小新课堂】对其他网站的隐私保护措施不负任何责任。我们可能在任何需要的时候增加商业伙伴，但是提供给他们的将仅仅是综合信息，我们将不会公开您的身份。
            <br />5、本隐私声明适用于【小新课堂】的所有相关服务，随着【小新课堂】服务范围的扩大，隐私声明的内容可由【小新课堂】随时更新，且毋须另行通知。更新后的隐私声明一旦在网页上公布即有效代替原来的隐私声明。
            <br />您需要向 【小新课堂】 提供何种信息？
            <br />如果您注册帐号以使用 「小新课堂」，我们将需要您提供一些信息来设置帐号（例如，设置个人资料并验证您的电话号码）。为了设置您的帐号，我们将需要昵称、您的手机号码和密码。您可以进一步完善您的个人资料。如果您使用 「小新课堂」 上提供的服务（例如，根据您的学习学段提供更精准的教育资源），我们将处理信息以运行服务。
            <br />收集的具体信息及应用权限授权包括：
            <table
              style="width: 100%;text-align: center;"
              border="1"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <td>信息</td>
                <td>可选/必选</td>
                <td>处理目的</td>
              </thead>
              <tbody>
                <tr>
                  <td>手机号码</td>
                  <td>必选</td>
                  <td>提供登录验证、密码找回、客服联系等</td>
                </tr>
                <tr>
                  <td>用户账户、密码、昵称</td>
                  <td>可选</td>
                  <td>提供部分场景登录和展示</td>
                </tr>
                <tr>
                  <td>微信授权登录信息</td>
                  <td>可选/部分场景必选</td>
                  <td>提供登录验证</td>
                </tr>
                <tr>
                  <td>性别、年龄、地址、学段、年级</td>
                  <td>可选</td>
                  <td>提供更精准的教育资源内容和服务</td>
                </tr>
                <tr>
                  <td>IP地址</td>
                  <td>可选</td>
                  <td>VOD服务访问来源，日志记录</td>
                </tr>
                <tr>
                  <td>GPS定位信息</td>
                  <td>可选/部分场景必选</td>
                  <td>与位置区域相关的教材版本推荐及日志记录统计</td>
                </tr>
                <tr>
                  <td>设备网络状态信息</td>
                  <td>手机必选权限</td>
                  <td>提供本地或网络信息服务支持，服务端不收集</td>
                </tr>
                <tr>
                  <td>读写外部存储</td>
                  <td>手机必选权限</td>
                  <td>头像图片读取、试卷等资源下载，服务端不收集</td>
                </tr>
                <tr>
                  <td>安装软件更新包</td>
                  <td>手机必选权限</td>
                  <td>APP更新升级，服务端不收集</td>
                </tr>
                <tr>
                  <td>手机状态和信息</td>
                  <td>手机必选权限</td>
                  <td>提供APP应用基本服务，服务端不收集</td>
                </tr>
                <tr>
                  <td>交易记录信息</td>
                  <td>必选</td>
                  <td>保留原始交易凭据</td>
                </tr>
                <tr>
                  <td>用户日志信息</td>
                  <td>必选</td>
                  <td>用户使用应用的交互记录，用于提供服务支持和问题分析及排查</td>
                </tr>
              </tbody>
            </table>
            <br />我们将如何使用您的信息？
            <br />我们使用您的信息为您提供 【小新课堂】，让您可以与其他用户交流，也让您可以使用 【小新课堂】应用及相关平台的功能。我们使用您的信息为您开立帐号，进而促进交流、访问教育资源、提供支持、让您可以使用【小新课堂】的功能，并用以改进【小新课堂】。【小新课堂】将您提供的联系渠道（例如，手机号码）用于验证和保护您的帐号以及一些重要管理目的，不会将这些渠道用于宣传或营销目的。
            <br />我们与谁共享您的数据？
            <br />我们不会与任何第三方共享您的信息，但为了提供服务（例如，为了进行帐号验证使用短信服务提供商；为了提供地图服务；为了提供其他兴趣点服务；使用我们的关联公司协助我们提供【小新课堂】服务）或接到法院、主管部门的指示或者法律强制要求而需要共享信息时除外。我们仅会出于本政策中所述的目的使用这些第三方服务处理或存储您的信息。我们与之共享用户信息的任何第三方（由我们选择）均须按照本政策规定，提供相同或同等的用户信息保护水平。
            <br />我们在哪里处理您的数据？
            <br />我们的服务器设在中国（阿里云的深圳、广州和上海区域），我们公司设在广州，我们还有国内其它地区的支持、工程和其他服务团队，支持向您提供【小新课堂】。我们可能会从这些位置访问您的数据。我们采取严格的内部控制措施，严格限定仅指定团队成员才能访问您的数据。
            <br />我们会将您的数据保存多长时间？
            <br />我们对您信息的保留时间取决于信息的类型 — 例如，登录数据（登录日志）的保留时间为自收集之日起最长 365 天。如果您指示我们删除您的【小新课堂】帐号，我们将在验证您的帐号所有权或收到您的帐号删除请求（以两者中时间较晚的为准）后的 60 天内删除您的信息。
            <br />我如何行使对自己数据所拥有的权利？
            <br />您可能对您的数据以及我们使用数据的方式拥有特殊权利，这些权利包括确定您访问数据的方式、擦除数据、限制您数据的使用方式、就数据使用提出异议以及获取您信息的副本。不同国家和地区的法律赋予【小新课堂】用户特定的权利有所差异,我们目前完全按照中国的法律法规执行，不考虑任何其它国家或地区的法律法规，如果不同意这一点，您可以拒绝使用【小新课堂】应用及相关服务。
            <br />我们如何向您通知变更？
            <br />在本隐私政策有任何重大变更时，我们将在变更生效之前在我们的官方网站及应用端更新本政策，我们不向向您发送另外的通知。
            <br />联系方式
            <br />如果您有任何顾虑或投诉，请点击这里与我们联系。
            <br />个人信息的销毁
            <br />我们会在个人信息的需求消失时（例如：此类个人信息的留存期届满且达到处理目的）对其进行销毁（法律或法规要求我们保留相关个人信息的情况除外）。不活跃帐号会在 3年后及购买服务到期后1年后自动删除（除非您的账户存在未到期的购买服务）。
            <br />个人信息的销毁程序如下：
            <br />销毁程序： 需要销毁的个人信息将按照我们的数据删除及保留协议，进行自动或人工销毁。
            <br />销毁方法：我们会以数据库物理删除等方法销毁以电子形式记录及储存的个人信息，以确保该类数据无法复制；使用物理删除并低级格式化等方式销毁记录和保存在硬拷贝备份的个人信息。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AOS from "aos";

export default {
    name:'index',
    data(){
        return{
            navbarIndex:0,
            navbarList:["首页","应用产品中心","资源内容中心","关于我们"],
            bodyHeihgt:0,
            topShow:false,
            start:true,
            imgText:'',
            imgShow:false,
        }
    },
    mounted(){
        this.bodyHeihgt = document.body.clientHeight*2
        window.addEventListener('scroll',this.scroll,true)
        let routePath = this.$route.path;
        console.log(routePath)
        if(routePath.indexOf('product')>-1){
            this.navbarIndex=1
        }
        else if(routePath.indexOf('resource')>-1){
            this.navbarIndex=2
        }
        else if(routePath.indexOf('aboutUs')>-1){
            this.navbarIndex=3
        }
        else{
            this.navbarIndex=0
        }
        AOS.init({
            offset: 200,   
            duration: 600,   
            easing: 'ease-in-sine',   
            delay: 100
        })
        setTimeout(()=>{
            this.start = false
        },5000)
    },
    destroyed(){
        window.removeEventListener("scroll", this.scroll, true)
    },
    methods:{
        routeVue(n){
            if(n==this.navbarIndex) return false;
            let path = '';
            switch(n){
                case 0:
                    path = '/'
                    break;
                case 1:
                    path = '/product'
                    break;
                case 2:
                    path = '/resource'
                    break;    
                case 3:
                    path = '/aboutUs'
                    break;      
                default:
                    break;
            }
            this.navbarIndex = n
            this.$router.push({path:path})
        },
        backTop(){
            let time = setInterval(() => {
                if(document.documentElement.scrollTop!=0||document.body.scrollTop!=0){
                    document.documentElement.scrollTop -= 50
                    document.body.scrollTop -= 50
                }
                else{
                    clearInterval(time)
                }
            }, 10);
            
            
        },
        scroll(){
            let scrollTop = window.pageYOffset ||document.documentElement.scrollTop||document.body.scrollTop;
            if(scrollTop>=this.bodyHeihgt){
                this.topShow = true
            }
            else{
                this.topShow = false;
            }
        },
        hrefWaring(){
          console.log('点击了')
            window.open("https://www.12377.cn/")
        },
        hrefGov(){
            window.open("https://beian.miit.gov.cn/")
        },
        //停止页面滚动
        stopMove(){
            let m = function(e){e.preventDefault();};
            document.body.style.overflow='hidden';
            document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
        },
        //开启页面滚动
        Move(){
            let m =function(e){e.preventDefault();};
            document.body.style.overflow='';//出现滚动条
            document.removeEventListener("touchmove",m,{ passive:true });
        },
        openImg(){
            this.imgShow = false;
            this.Move()
        },
        showImg(n){
            n==1?this.imgText='广播电视节目制作经营许可证':n==2?this.imgText='网络文化经营许可证':n==3?this.imgText='营业执照':n==4?this.imgText='用户服务协议':n==5?this.imgText='隐私政策':this.imgText='出版物经营许可证';
            this.imgShow = true;
            this.stopMove()
        },
    }
}
</script>
<style lang="scss">
.index {
  width: 100%;
  height: 100%;
  background: #ffffff;
  transition: all 0.5s linear;
  padding-top: 88px;
  box-sizing: border-box;
  .commonHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    height: 100%;
    height: 88px;
    background: #ffffff;
    box-shadow: 0px 6px 24px rgba(35, 73, 122, 0.06);
    z-index: 12;
    .headercontain {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .headerLeft {
        width: 243px;
        height: 80px;
        position: relative;
        overflow: hidden;
        margin-top: -4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .headerLeft::before{
          display: block;
          content: "";
          position: absolute;
          top:0;
          width: 20px;
          height: 100px;
          background: rgba(255,255,255,.4);
          transform: skew(-45deg);
          animation: leftS 1.8s linear infinite;
      }
      .headerRight {
        height: 100%;
        .navbar {
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          .navbar_item {
            height: 100%;
            line-height: 88px;
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            margin-left: 60px;
          }
          .navbar_itemActive {
            position: relative;
            color: #3bcd0d;
          }
          .navbar_item:hover {
            color: #3bcd0d;
          }
          .navbar_itemActive::after {
            position: absolute;
            display: block;
            content: "";
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            bottom: 23px;
            height: 4px;
            background: #3bcd0d;
            border-radius: 4px;
            animation: sclaes 0.3s linear;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 168px;
    background: #21282d;
    display: flex;
    align-items: center;
    justify-content: center;
    &Main {
      cursor: pointer;
      text-align: center;
      .footerNav {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.68;
        span {
          padding: 0 16px;
          position: relative;
          img {
            display: none;
            width: 200px;
            height: 200px;
            position: absolute;
            top: -216px;
            left: 50%;
            transform: translateX(-50%);
            background: silver;
          }
        }
      }
      .footercompany {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.68;
        margin-top: 20px;
        i {
          font-style: normal;
          padding: 0 12px;
        }
      }
    }
  }
  .ad {
    position: fixed;
    right: 10%;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 9;
    .adList {
      width: 76px;
      height: 200px;
      background: #ffffff;
      border: 1px solid #f6f6f6;
      border-radius: 60px;
      padding: 34px 0 32px;
      box-sizing: border-box;
      position: relative;
      .adItem {
        width: 100%;
        cursor: pointer;
        position: relative;
        &Img {
          width: 30px;
          height: 30px;
          background: url("../assets/icon_saoma.png") no-repeat;
          background-size: 100% 100%;
          display: block;
          margin: 0 auto;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          margin-top: 8px;
          color: #6e6e6e;
          text-align: center;
        }
        .adItemDetail {
          position: absolute;
          left: -180px;
          top: 50%;
          transform: translateY(-50%);
          width: 164px;
          height: 200px;
          background: #ffffff;
          border: 1px solid #f6f6f6;
          border-radius: 20px;
          padding: 20px 0px 0;
          box-sizing: border-box;
          display: none;
          img {
            display: block;
            width: 120px;
            height: 120px;
            margin: 0 auto;
          }
          p {
            font-size: 12px !important;
            font-weight: 400;
            line-height: 20px;
            color: #6e6e6e !important;
            text-align: center;
            margin: 0;
          }
          .green {
            color: #3bcd0d !important;
            margin-top: 9px;
          }
        }
      }
      .adItem:hover {
        .adItemImg {
          background: url("../assets/icon_saomas.png") no-repeat;
        }
        p {
          color: #000000;
        }
        .adItemDetail {
          display: block;
        }
      }
      .adItem:nth-child(2) {
        border-top: 1px solid #f6f6f6;
        margin-top: 16px;
        padding-top: 21px;
        .adItemImg {
          height: 26px;
          background: url("../assets/icon_hezuo.png") no-repeat;
        }
      }
      .adItem:nth-child(2):hover .adItemImg {
        background: url("../assets/icon_hezuos.png") no-repeat;
      }
      .girl {
        position: absolute;
        top: -69px;
        right: 38px;
        width: 140px;
        height: 212px;
        animation: shows 20s linear infinite;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .scrollTop {
      position: absolute;
      bottom: -88px;
      right: 0;
      width: 76px;
      height: 76px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .scrollTop:hover img{
        box-shadow: 0px 6px 24px rgba(35, 73, 122, 0.06);
    }
  }
  .imageMask {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.12);
    z-index: 14;
    display: flex;
    align-items: center;
    justify-content: center;
    .imageContain {
      width: 1144px;
      height: 700px;
      background: #ffffff;
      border-radius: 20px;
      position: relative;
      padding: 0 27px;
      box-sizing: border-box;
      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 41px;
        color: #000000;
        padding: 12px 0;
      }
      .imageOpen {
        position: absolute;
        right: 27px;
        top: 22px;
        width: 54px;
        height: 22px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 604px;
        background: #f4f7f9;
        border-radius: 20px;
        img {
          max-height: 100%;
          max-height: 100%;
        }
        .text {
          width: 998px;
          height: 100%;
          margin: 0 auto;
          padding: 31px 0;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #000000;
          overflow-y: auto;
          white-space: pre-line;
          box-sizing: border-box;
        }
      }
    }
  }
}
@keyframes sclaes {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes shows {
  0% {
    opacity: 1;
    right: 0;
    top: 0;
  }
  5% {
    right: 38px;
    top: -69px;
    opacity: 1;
  }
  50%{
    right: 38px;
    top: -69px;
    opacity: 1;
  }
  55% {
    right: 0px;
    top: 0px;
    opacity: 1;
  }
  56%{
    right: 0px;
    top: 0px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes leftS{
    0%{left: -20%;}
    100%{left: 120%;}
}
</style>